.nav-link.active {
  font-weight: bold;
  color: #007bff; /* Example color */
}
.fixed-size-img {
  width: 100%; /* Makes the image take the full width of the parent container */
  height: 200px; /* Sets a fixed height for the images */
  object-fit: cover; /* Ensures the images cover the container without stretching */
}
.cap {
  display: block;
  font-size: 20px;
  margin-bottom: 20px;
}
